import React, { useState, useEffect } from "react";
import AuthModal from "../components/AuthModal";
import Backdrop from "../components/Backdrop";
import { ToastContainer, toast } from "react-toastify";
import { getUser, removeUser } from "../services/user";
import AddStoryModal from "./AddStoryModal";
import { useDispatch } from "react-redux";
import { changeStoryCharacter } from "../redux/actions/storyAction";
import { changeLoggedInUser } from "../redux/actions/loginAction";
import { Link } from "react-router-dom";

export default function AuthButton() {
  /* Variable declaration  */
  const [loginStatus, setLoginStatus] = useState(false);
  const [isModalActive, setModalActive] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAddNewStoryModalActive, setIsAddNewStoryModalActive] =
    useState(false);
  const [user, setUser] = useState(getUser());

  const handleToast = (notification) => toast(notification);

  /* Redux */
  const dispatch = useDispatch();

  function openModalHandler() {
    setModalActive(true);
  }

  function closeModalHandler() {
    setModalActive(false);
  }

  function handleLogout() {
    removeUser();
    setUser(getUser());
    dispatch(changeLoggedInUser({}));
    dispatch(changeStoryCharacter(null));
    setLoginStatus(false);
    setModalActive(false);
    window.location.reload();
  }

  function openAddStoryModalHandler() {
    setIsAddNewStoryModalActive(true);
  }

  function closeAddStoryModalHandler() {
    setIsAddNewStoryModalActive(false);
  }

  useEffect(() => {
    const userResult = getUser();
    if (!!userResult) {
      setUser(userResult);
      setIsAdmin(userResult.isAdmin);
      setLoginStatus(true);
    } else {
      setLoginStatus(false);
    }
  }, [loginStatus]);

  if (loginStatus) {
    if (isAdmin)
      return (
        <>
          <div id="auth-button">
            <h6 className="loggedInUser mt-5">{user.email} - Admin</h6>
            <div
              className="btn-group btn-grp"
              role="group"
              aria-label="Basic example"
            >
              <button
                type="button"
                className="btn btn-primary"
                onClick={openAddStoryModalHandler}
              >
                Add New Story
              </button>
              <button
                type="button"
                onClick={handleLogout}
                className="btn btn-dark"
              >
                Logout
              </button>

              {/*<Link to={"/bucket/view/"}>
                <a className="btn btn-primary">S3 Bucket Data</a>
              </Link>*/}              
            </div>
          </div>
          {isAddNewStoryModalActive && (
            <AddStoryModal
              handleToast={handleToast}
              closeHandler={closeAddStoryModalHandler}
            />
          )}
          <ToastContainer />
        </>
      );

    return (
      <div id="auth-button">
        <h6 className="loggedInUser mt-5">{user.email}</h6>
        <button
          id="auth-btn"
          type="button"
          onClick={handleLogout}
          className="btn btn-dark"
        >
          Logout
        </button>
        <ToastContainer />
      </div>
    );
  }

  return (
    <>
      <div id="auth-button">
        <button
          type="button"
          id="auth-btn"
          className="btn btn-dark mt-5"
          onClick={openModalHandler}
        >
          Login or Register
        </button>
      </div>

      {isModalActive && (
        <AuthModal
          setLoginStatus={setLoginStatus}
          handleToast={handleToast}
          closeHandler={closeModalHandler}
        />
      )}
      {isModalActive && <Backdrop onClick={closeModalHandler} />}
      <ToastContainer />
    </>
  );
}
