import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { GET_ALL_STORIES_API } from "../../constant";
import Story from "../../components/Story";
import {
  changeSelectedStory,
  changeStoryDetail,
} from "../../redux/actions/storyAction";
import { useDispatch, useSelector } from "react-redux";
import { changeActiveUserType } from "../../redux/actions/loginAction";
import { getData } from "../../services/data";

export default function Stories() {
  let navigate = useNavigate();

  /* Variable declaration  */
  const [storiesLoaded, setStoriesLoaded] = useState(false);

  // Redux for story list
  const dispatch = useDispatch();
  const { stories } = useSelector((state) => state.StoryReducer);
  const { loggedInUser } = useSelector((state) => state.LoginReducer);

  // API call for Story List
  const fetchStories = async () => {
    try {
      let response = await axios.get(GET_ALL_STORIES_API);
      let json = await response.data;
      return { success: true, data: json };
    } catch (error) {
      return { success: false };
    }
  };

  // API call for Story according to ID
  const fetchStory = async (storyId) => {
    try {
      const response = await axios.get(`${GET_ALL_STORIES_API}/${storyId}`);
      const json = await response.data;
      dispatch(changeSelectedStory({ ...json.data }));
      return { success: true, data: json };
    } catch (error) {
      return { success: false };
    }
  };

  // Onclick of Join button
  const selectStory = async (storyId) => {
    setStoriesLoaded(false);
    const result = await fetchStory(storyId);
    if (result.success) {
      const isInvitedUser = getData("asp_" + storyId.toString());
      if (loggedInUser && loggedInUser._id && !loggedInUser.isAdmin) {
        result.data.data.conductorId.forEach((et) => {
          if (et._id.toString() === loggedInUser._id.toString()) {
            dispatch(changeActiveUserType("conductor"));
          }
        });
      } else if (loggedInUser && loggedInUser.isAdmin) {
        dispatch(changeActiveUserType("admin"));
      } else if (isInvitedUser) {
        dispatch(changeActiveUserType("invitedUser"));
      } else {
        dispatch(changeActiveUserType("user"));
      }
      navigate("../story/" + storyId.toString());
    } else setStoriesLoaded(true);
  };

  useEffect(() => {
    (async () => {
      setStoriesLoaded(false);
      let res = await fetchStories();
      if (res.success) {
        await dispatch(changeStoryDetail(res.data.data));
        setStoriesLoaded(true);
      }
      setStoriesLoaded(true);
    })();
  }, []);

  return (
    <React.Fragment>
      {storiesLoaded ? (
        <div className="container">
          <div className="story-section">
            <div className="row mt-4">
              {stories.map((story, i) => (
                <Story
                  key={`story_${i}`}
                  selectStory={selectStory}
                  story={story}
                ></Story>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
}
