import { ACTIVE_USER_TYPE, LOGGED_IN_USER } from "../constants/loginConstant"

const initialState = {
    activeUserType: null,
    loggedInUser: null,
}

export const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGGED_IN_USER:
            state.loggedInUser = action.newState;
            return {
                ...state,
            }
        case ACTIVE_USER_TYPE:
            state.activeUserType = action.newState;
            return {
                ...state,
            }
        default:
            return state
    }
}