import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import rootReducer from "./mainReducer";

const history = createBrowserHistory();
const middleware = routerMiddleware(history);

export const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware, middleware)
);
