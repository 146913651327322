import { STORY_DETAIL, STORY_CHARACTER, SELECTED_STORY, STORY_INVITATION, STORY_ALLCHARACTERS, STORY_ALLMESSAGES, STORY_ALLPLAYERS } from "../constants/storyConstant"


const changeStoryDetail = (newState) => {
    return { type: STORY_DETAIL, newState }
}

const changeStoryCharacter = (newState) => {
    return { type: STORY_CHARACTER, newState }
}

const changeSelectedStory = (newState) => {
    return { type: SELECTED_STORY, newState }
}

const changeStoryInvitation = (newState) => {
    return { type: STORY_INVITATION, newState }
}

const changeAllCharacter = (newState) => {
    return { type: STORY_ALLCHARACTERS, newState }
}

const changeAllPlayer = (newState) => {
    return { type: STORY_ALLPLAYERS, newState }
}

const changeAllMessage = (newState) => {
    return { type: STORY_ALLMESSAGES, newState }
}

export {
    changeStoryDetail,
    changeStoryCharacter,
    changeSelectedStory,
    changeStoryInvitation,
    changeAllCharacter,
    changeAllPlayer,
    changeAllMessage
}