import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ACCEPT_INVITATION_BY_CODE,
  GET_ALL_STORIES_API,
  GET_INVITATION,
  validatePhoneNumber,
} from "../../constant";
import { setData } from "./../../services/data";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeActiveUserType } from "../../redux/actions/loginAction";
import { changeStoryInvitation } from "../../redux/actions/storyAction";

export default function Invitation(props) {
  const params = useParams();
  let navigate = useNavigate();

  /* Redux */
  const dispatch = useDispatch();
  const { loggedInUser } = useSelector((state) => state.LoginReducer);

  /* Variable declaration  */
  const [inviteId, setInviteId] = useState(params.inviteId);
  const [joinStoryPath, setJoinStoryPath] = useState(
    window.location.pathname.split("/")[1]
  );

  const [inviteInvalid, setInviteInvalid] = useState(false);
  const [invitationData, setInvitationData] = useState(null);
  const [playerPhoneNumber, setPlayerPhoneNumber] = useState("");
  const [playerCode, setPlayerCode] = useState("");
  const [isPlayerCodeValid, setIsPlayerCodeValid] = useState(false);

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

  const fetchInvite = async () => {
    try {
      let response = await axios.get(GET_INVITATION + "/" + inviteId);
      let json = await response.data;
      return { success: true, data: json };
    } catch (error) {
      return { success: false };
    }
  };
  /* This useEffect is to fetch and check invite  */
  useEffect(() => {
    (async () => {
      if (inviteId) {
        let res = await fetchInvite();
        if (res.success) {
          if (res.data.data.length == 0) {
            setInviteInvalid(true);
          } else {
            setInvitationData(res.data.data[0]);
            if (res.data.data[0].invitationStatus == "accepted") {
              setData("asp_" + res.data.data[0].storyId._id.toString(), {
                ...res.data.data[0],
              });
              dispatch(changeStoryInvitation({ ...res.data.data[0] }));
              if (loggedInUser && loggedInUser._id && !loggedInUser.isAdmin) {
                res.data.data[0].storyId.conductorId.forEach((et) => {
                  if (et._id.toString() === loggedInUser._id.toString()) {
                    dispatch(changeActiveUserType("conductor"));
                  }
                });
              } else if (loggedInUser && loggedInUser.isAdmin) {
                dispatch(changeActiveUserType("admin"));
              } else {
                dispatch(changeActiveUserType("user"));
              }
              navigate("../story/" + res.data.data[0].storyId._id.toString());
            }
          }
          //set in local storage
          //character
        }
      }
    })();
  }, []);

  const doAcceptInviteAccept = async () => {
    try {
      let response = await axios.post(
        GET_INVITATION + "/" + inviteId + "/accept",
        {
          playerPhoneNumber,
        }
      );
      let json = await response.data;
      return { success: true, data: json };
    } catch (error) {
      return { success: false };
    }
  };

  const doAcceptInviteByCode = async () => {
    try {
      let response = await axios.post(ACCEPT_INVITATION_BY_CODE, {
        code: playerCode,
      });
      let json = await response.data;
      return { success: true, data: json };
    } catch (error) {
      return { success: false };
    }
  };

  /* This fuction will call on click of accept button in case of Link */
  const handleAcceptInviteByPhone = async () => {
    if (playerPhoneNumber !== "" && validatePhoneNumber(playerPhoneNumber)) {
      let res = await doAcceptInviteAccept();
      if (res.success) {
        // set in local storage and then redirect to the story page to chat
        setData("asp_" + invitationData.storyId._id.toString(), {
          ...invitationData,
          mobile: playerPhoneNumber,
        });
        navigate("../story/" + invitationData.storyId._id.toString());
        dispatch(changeStoryInvitation({ ...invitationData }));
        window.location.reload();
      }
    } else {
      setIsPhoneNumberValid(true);
    }
  };

  /* This fuction will call on click of accept button in case of Code */
  const handleAcceptInviteByCode = async () => {
    if (playerCode !== "") {
      let res = await doAcceptInviteByCode();
      if (res.success) {
        setInvitationData(res.data.data[0]);
        if (res.data.data[0].invitationStatus == "accepted") {
          setData("asp_" + res.data.data[0].storyId._id.toString(), {
            ...res.data.data[0],
          });
          dispatch(changeStoryInvitation({ ...res.data.data[0] }));
          if (loggedInUser && loggedInUser._id && !loggedInUser.isAdmin) {
            res.data.data[0].storyId.conductorId.forEach((et) => {
              if (et._id.toString() === loggedInUser._id.toString()) {
                dispatch(changeActiveUserType("conductor"));
              }
            });
          } else if (loggedInUser && loggedInUser.isAdmin) {
            dispatch(changeActiveUserType("admin"));
          } else {
            dispatch(changeActiveUserType("user"));
          }
          navigate("../story/" + res.data.data[0].storyId._id.toString());
        }
      }
    } else {
      setIsPlayerCodeValid(true);
    }
  };
  if (
    invitationData === null &&
    joinStoryPath !== "joinstory" &&
    !inviteInvalid
  )
    return (
      <>
        <h1 className="m-5">Loading...</h1>
      </>
    );

  if (inviteInvalid === true)
    return (
      <main className="msger-chat text-center bg-dark msger-chat-code">
        <img className="logo" src="/images/ssn-logo.png" />
        <h5 className="join-invited">Invalid Invitation Link</h5>
      </main>
    );
  if (invitationData !== null)
    return (
      <main className="msger-chat text-center bg-dark py-4 mt-3 msger-chat-code">
        <img className="logo" src="/images/ssn-logo.png" />
        <h5 className="join-invited">
          You have been invited to join <br />
          <b>
            <i>"{invitationData.storyId.title}"</i>
          </b>{" "}
          on Secret Story Network!
        </h5>
        <h5 className="join-invited">Your Character is</h5>
        <h2>{invitationData.characterId.name}</h2>
        <h5 className="join-invited">
          {invitationData.characterId.biography && (
            <>
              {" "}
              <b>Bio :</b> {invitationData.characterId.biography}
            </>
          )}
        </h5>
        <br />
        <h5 className="join-invited">
          Please accept this invite by adding
          <br /> your mobile number with country code <br />
          (UK is +44 with no zero then number)
          <br /> so that we can alert you about the playing time and send you a
          link via SMS.
        </h5>

        <input
          type="text"
          className="number-input"
          name="phoneNumber"
          maxLength={15}
          placeholder="Example: +447911123456"
          value={playerPhoneNumber}
          onChange={(et) => {
            setPlayerPhoneNumber(et.target.value);
            setIsPhoneNumberValid(false);
          }}
          required={true}
        />
        {isPhoneNumberValid && (
          <div className="text-danger bg-light fw-bolder">
            Phone Number field is required, please insert the valid number.
          </div>
        )}
        <br />

        <h5 className="join-invited">
          By accepting this invite to play,
          <br /> You are confirming you are happy to take part in this research
          project -{" "}
          <a
            href="http://www.secretstorynetwork.com/Participant_information_consent.pdf"
            target="_blank"
            className="invite-pdf-link"
          >
            {" "}
            details here
          </a>
        </h5>

        <br />
        <button className="thanks" onClick={() => navigate("/")}>
          No, thanks
        </button>
        <button
          className="accept"
          onClick={() => {
            handleAcceptInviteByPhone();
          }}
        >
          Accept invite
        </button>
      </main>
    );

  if (joinStoryPath === "joinstory") {
    return (
      <main className="msger-chat text-center bg-dark py-4 mt-3 msger-chat-code">
        <div className="inner-blk">
          <img className="logo-code" src="/images/ssn-logo.png" />

          <h5 className="join-by-code">Enter your code to enter the story</h5>

          <input
            type="text"
            className="number-input player-code"
            name="code"
            maxLength={4}
            value={playerCode}
            onChange={(et) => {
              setPlayerCode(et.target.value);
              setIsPlayerCodeValid(false);
            }}
            required={true}
          />
          {isPlayerCodeValid && (
            <div className="text-danger bg-light fw-bolder">
              Code field is required, please insert the valid code.
            </div>
          )}
          <br />

          <div className="buttons">
            <button className="thanks mb-5" onClick={() => navigate("/")}>
              No, thanks
            </button>
            <button
              className="accept mb-5"
              onClick={() => {
                handleAcceptInviteByCode();
              }}
            >
              Accept invite
            </button>
          </div>
        </div>
      </main>
    );
  }
}
