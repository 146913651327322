import { ACTIVE_USER_TYPE, LOGGED_IN_USER } from "../constants/loginConstant"

const changeLoggedInUser = (newState) => {
    return { type: LOGGED_IN_USER, newState }
}

const changeActiveUserType = (newState) => {
    return { type: ACTIVE_USER_TYPE, newState }
}

export { changeLoggedInUser, changeActiveUserType }