import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeStoryCharacter } from "../redux/actions/storyAction";
import $ from "jquery";
import { useNavigate, useLocation } from "react-router-dom";

export default function Navbar(props) {
  let navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  /* Redux */
  const dispatch = useDispatch();

  const handleStoryData = (e) => {
    dispatch(changeStoryCharacter(null));
    navigate("/");
  };

  /* This useEffect for mobile responsive */
  useEffect(() => {
    if (props.title)
      if (props.title.length > 20) {
        $("#app-heading p").addClass("animated-text");
      } else {
        $("#app-heading p").removeClass("animated-text");
      }
  }, [props.title]);

  if (pathname === "/") {
    document.body.classList.add("custom-scroll");
  } else {
    document.body.classList.remove("custom-scroll");
  }
  return (
    <>
      <nav className="navbar navbar-dark bg-dark fixed-top py-0" id="navbar">
        <div className="container">
          <div className="navbar-brand">
            {props.backButton ? (
              <>
                <a className="btn btn-sm btn-info" onClick={handleStoryData}>
                  Back
                </a>
                <div id="app-heading">
                  <p>{props.title}</p>
                </div>
              </>
            ) : (
              <>
                <img
                  src="/images/ssn-logo.png"
                  alt="icon"
                  width="29"
                  height="24"
                />
                <span id="app-heading">{props.title}</span>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
