import jwt_decode from "jwt-decode";
let user = {};

export const getUser = () => {
    if (user.email != undefined)
        return user;
    let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
    if (isLoggedIn) {
        let token = localStorage.getItem('t');
        setUser(token);
        return user;
    }
    else
        return false;
}

export const setUser = token => {
    localStorage.setItem('t', token);
    localStorage.setItem('isLoggedIn', true);
    //decode and save 
    const userData = jwt_decode(token);
    user = userData
    localStorage.setItem('user', JSON.stringify(user));
    return;
}

export const loadUserIfExists = () => {
    //check JWT to//if jwt parse and save datra to user
    let isUserExists = localStorage.getItem('isLoggedIn');
    isUserExists = isUserExists ? JSON.parse(isUserExists) : false;
    if (isUserExists) {
        user = JSON.parse(localStorage.getItem('user'));
    }
}

export const removeUser = () => {
    user = {};
    localStorage.setItem('isLoggedIn', false);
    localStorage.removeItem('t');
    localStorage.removeItem('user');
}