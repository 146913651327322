import { STORY_DETAIL, STORY_CHARACTER, SELECTED_STORY, STORY_INVITATION, STORY_ALLCHARACTERS, STORY_ALLPLAYERS, STORY_ALLMESSAGES } from "../constants/storyConstant"

const initialState = {
    stories: [],
    selectedStory: null,
    selectedCharacter: null,
    activeInvitation: null,
    allMessages: [],
    allPlayers: [],
    allCharacters: [],
}

export const StoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORY_DETAIL:
            state.stories = action.newState;
            return {
                ...state,
            }
        case STORY_CHARACTER:
            state.selectedCharacter = action.newState;
            return {
                ...state,
            }
        case SELECTED_STORY:
            state.selectedStory = action.newState;
            return {
                ...state,
            }
        case STORY_INVITATION:
            state.activeInvitation = action.newState;
            return {
                ...state,
            }
        case STORY_ALLCHARACTERS:
            state.allCharacters = action.newState;
            return {
                ...state,
            }
        case STORY_ALLPLAYERS:
            state.allPlayers = action.newState;
            return {
                ...state,
            }
        case STORY_ALLMESSAGES:
            state.allMessages = action.newState;
            return {
                ...state,
            }
        default:
            return state
    }
}