import React from "react";

export default function Story(props) {
  return (
    <div className="col-sm-6 col-md-4 col-lg-3">
      <div
        className="card"
        style={{ cursor: "pointer" }}
        onClick={() => {
          props.selectStory(props.story._id.toString());
        }}
      >
        <div className="story-img">
          <img
            src={props.story.imageUrl=='https://app.secretstory.network/images/ssn-logo.svg' ? "/images/ssn-logo.png" : props.story.imageUrl}            
            className="card-img-top"
            alt="story cover image"
          />
        </div>
        <div className="card-body">
          <h5 className="card-title">{props.story.title}</h5>
          <p className="card-text">{props.story.description}</p>
          <button className="btn btn-primary">Join</button>
        </div>
      </div>
    </div>
  );
}
