const STORY_DETAIL = "STORY_DETAIL";
const STORY_CHARACTER = 'STORY_CHARACTER';
const SELECTED_STORY = 'SELECTED_STORY';
const STORY_INVITATION = 'STORY_INVITATION';
const STORY_ALLCHARACTERS = 'STORY_ALLCHARACTERS';
const STORY_ALLPLAYERS = 'STORY_ALLPLAYERS';
const STORY_ALLMESSAGES = 'STORY_ALLMESSAGES';

export {
    STORY_DETAIL,
    STORY_CHARACTER,
    SELECTED_STORY,
    STORY_ALLCHARACTERS,
    STORY_INVITATION,
    STORY_ALLPLAYERS,
    STORY_ALLMESSAGES
}