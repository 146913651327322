import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Loader from "./Loader";
import { AUTH_LOGIN_API, AUTH_REGISTER } from "../constant";
import { getUser, setUser } from "../services/user";
import { useDispatch } from "react-redux";
import {
  changeActiveUserType,
  changeLoggedInUser,
} from "../redux/actions/loginAction";

export default function AuthModal(props) {
  /* Variable declaration  */
  const [modalType, setModalType] = useState("login");
  const [isInProgress, setInProgress] = useState(false);
  const [userLoginCred, setUserLoginCred] = useState({
    email: "",
    password: "",
  });
  const [userLoginCredValid, setUserLoginCredValid] = useState({
    email: false,
    password: false,
  });
  const [userRegCred, setUserRegCred] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [userRegCredValid, setUserRegCredValid] = useState({
    name: "",
    email: "",
    password: "",
  });

  /* Redux */
  const dispatch = useDispatch();

  function tabChangeHandler(type) {
    setModalType(type);
  }

  const doLogin = async (data) => {
    try {
      let response = await axios.post(AUTH_LOGIN_API, { ...data });
      let json = await response.data;
      return { success: true, data: json };
    } catch (error) {
      props.handleToast(
        error && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Something went wrong."
      );
      return { success: false };
    }
  };

  const doRegister = async (data) => {
    try {
      let response = await axios.post(AUTH_REGISTER, { ...data });
      let json = await response.data;
      return { success: true, data: json };
    } catch (error) {
      props.handleToast(
        error && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Something went wrong."
      );
      return { success: false };
    }
  };

  const handleLogin = async () => {
    if (userLoginCred.email !== "" && userLoginCred.password !== "") {
      setInProgress(true);
      let res = await doLogin(userLoginCred);
      if (res.success) {
        setUser(res.data.data.token);
        setUserLoginCred({ email: "", password: "" });
        setInProgress(false);
        dispatch(changeLoggedInUser(getUser()));
        let userData = getUser();
        if (userData && !userData.isAdmin) {
          dispatch(changeActiveUserType("conductor"));
        }

        props.setLoginStatus(true);
      } else setInProgress(false);
    } else if (userLoginCred.email === "" && userLoginCred.password !== "") {
      setUserLoginCredValid({
        email: true,
        password: false,
      });
      setInProgress(false);
    } else if (userLoginCred.password === "" && userLoginCred.email !== "") {
      setUserLoginCredValid({
        email: false,
        password: true,
      });
      setInProgress(false);
    } else {
      setUserLoginCredValid({
        email: true,
        password: true,
      });
      setInProgress(false);
    }
  };

  const handleRegister = async () => {
    if (
      userRegCred.email !== "" &&
      userRegCred.password !== "" &&
      userRegCred.name !== ""
    ) {
      setInProgress(true);
      let res = await doRegister(userRegCred);
      if (res.success) {
        setUserRegCred({ email: "", password: "", name: "" });
        setModalType("login");
        props.handleToast(
          "User created successfully, please login to continue."
        );
      }
      setInProgress(false);
    } else {
      setUserRegCredValid({
        email: true,
        password: true,
        name: true,
      });
      setInProgress(false);
    }
  };

  if (isInProgress) return <Loader />;

  if (modalType === "login")
    return (
      <>
        <div
          className="modal fade show"
          id="exampleModal"
          style={{ display: "block" }}
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      aria-current="page"
                      onClick={() => {
                        tabChangeHandler("login");
                      }}
                    >
                      Login
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => {
                        tabChangeHandler("register");
                      }}
                    >
                      Register
                    </a>
                  </li>
                </ul>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={props.closeHandler}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <h3>Login</h3>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      placeholder="Enter Email Address Here"
                      className="form-control"
                      value={userLoginCred.email}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(et) => {
                        setUserLoginCred({
                          ...userLoginCred,
                          email: et.target.value,
                        });
                        setUserLoginCredValid({
                          email: false,
                          password: false,
                        });
                      }}
                      required
                    />
                    {userLoginCredValid.email && (
                      <div className="invalid-form mt-1">
                        Email field cannot be blank.
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      placeholder="Enter Password Here"
                      className="form-control"
                      value={userLoginCred.password}
                      id="exampleInputPassword1"
                      onChange={(et) => {
                        setUserLoginCred({
                          ...userLoginCred,
                          password: et.target.value,
                        });
                        setUserLoginCredValid({
                          email: false,
                          password: false,
                        });
                      }}
                      required
                    />
                    {userLoginCredValid.password && (
                      <div className="invalid-form mt-1">
                        Password field cannot be blank.
                      </div>
                    )}
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={props.closeHandler}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  else if (modalType === "register")
    return (
      <>
        <div
          className="modal fade show"
          id="exampleModal"
          style={{ display: "block" }}
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => {
                        tabChangeHandler("login");
                      }}
                    >
                      Login
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      aria-current="page"
                      onClick={() => {
                        tabChangeHandler("register");
                      }}
                    >
                      Register
                    </a>
                  </li>
                </ul>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={props.closeHandler}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <h3>Register</h3>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Name
                    </label>
                    <input
                      type="name"
                      placeholder="Enter Name here"
                      className="form-control"
                      id="exampleInputEmail1"
                      value={userRegCred.name}
                      onChange={(et) => {
                        setUserRegCred({
                          ...userRegCred,
                          name: et.target.value,
                        });
                        setUserRegCredValid({
                          email: false,
                          password: false,
                          name: false,
                        });
                      }}
                      aria-describedby="emailHelp"
                    />
                    {userRegCredValid.name && (
                      <div className="invalid-form mt-1">
                        Name field cannot be blank.
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      placeholder="Enter Email Address Here"
                      className="form-control"
                      id="exampleInputEmail1"
                      value={userRegCred.email}
                      onChange={(et) => {
                        setUserRegCred({
                          ...userRegCred,
                          email: et.target.value,
                        });
                        setUserRegCredValid({
                          email: false,
                          password: false,
                          name: false,
                        });
                      }}
                      aria-describedby="emailHelp"
                    />

                    {userRegCredValid.email ? (
                      <div className="invalid-form mt-1">
                        Email field cannot be blank.
                      </div>
                    ) : (
                      <div id="emailHelp" className="form-text text-primary">
                        We'll never share your email with anyone else.
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      placeholder="Enter Password Here"
                      className="form-control"
                      value={userRegCred.password}
                      onChange={(et) => {
                        setUserRegCred({
                          ...userRegCred,
                          password: et.target.value,
                        });
                        setUserRegCredValid({
                          email: false,
                          password: false,
                          name: false,
                        });
                      }}
                      id="exampleInputPassword1"
                    />
                    {userRegCredValid.password && (
                      <div className="invalid-form mt-1">
                        Password field cannot be blank.
                      </div>
                    )}
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={props.closeHandler}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleRegister}
                >
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
