import React, { useState } from "react";
import Loader from "./Loader";
import axios from "axios";
import { CREATE_NEW_STORY_API } from "../constant";
import { useDispatch, useSelector } from "react-redux";
import { changeStoryDetail } from "../redux/actions/storyAction";

export default function AddStoryModal(props) {
  /* Variable declaration  */
  const [storyName, setStoryName] = useState("");
  const [isInProgress, setInProgress] = useState(false);
  const { stories } = useSelector((state) => state.StoryReducer);

  /* Redux */
  const dispatch = useDispatch();

  const doCreateStory = async (storyName) => {
    try {
      let response = await axios.post(CREATE_NEW_STORY_API, {
        title: storyName,
      });
      let json = await response.data;
      const storie = [...stories, { ...json.data }];
      await dispatch(changeStoryDetail(storie));
      return { success: true, data: json };
    } catch (error) {
      props.handleToast(
        error && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Something went wrong."
      );
      return { success: false };
    }
  };

  const handleCreateStory = async () => {
    setInProgress(true);
    let res = await doCreateStory(storyName);
    if (res.success) {
      props.handleToast("Story created successfully.");
      setStoryName("");
      setInProgress(false);
      props.closeHandler();
    } else setInProgress(false);
  };

  if (isInProgress) return <Loader />;

  return (
    <>
      <div
        className="modal fade show"
        id="exampleModal"
        style={{ display: "block" }}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form>
                <h3>Add New Story</h3>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Story Name Here"
                    className="form-control"
                    value={storyName}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={(et) => {
                      setStoryName(et.target.value);
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={props.closeHandler}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleCreateStory}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
