import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Stories from "./pages/stories/Stories";
import Navbar from "./components/Navbar";
import AuthButton from "./components/AuthButton";
import { loadUserIfExists, getUser } from "./services/user";
import Invitation from "./pages/stories/Invitation";
import { GET_ALL_STORIES_API, SOCKET_URL } from "./constant";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  changeStoryCharacter,
  changeStoryDetail,
} from "./redux/actions/storyAction";
import {
  changeActiveUserType,
  changeLoggedInUser,
} from "./redux/actions/loginAction";
import Loader from "./components/Loader";

const StoryView = React.lazy(() => import("./pages/stories/StoryView"));
const BucketView = React.lazy(() => import("./pages/stories/BucketView"));
const MessagesMediaView = React.lazy(() => import("./pages/stories/MessagesMediaView"));


function App() {
  /* Declare a state variable */
  const [socket, setSocket] = useState(null);
  const [socketReady, setSocketReady] = useState(false);

  /* Redux */
  const dispatch = useDispatch();
  const { selectedStory } = useSelector((state) => state.StoryReducer);

  /* For connecting socket */
  function connectSocket() {
    const newSocket = io(`${SOCKET_URL}`);
    setSocket(newSocket);
    setSocketReady(true);
  }

  const fetchStories = async () => {
    try {
      let response = await axios.get(GET_ALL_STORIES_API);
      let json = await response.data;
      return { success: true, data: json };
    } catch (error) {
      return { success: false };
    }
  };

  useEffect(() => {
    setSocketReady(false);
    connectSocket();
    return () => {
      if (socket) {
        setSocketReady(false);
        socket.close();
      }
    };
  }, []);

  // check if the user already logged in if yes set login status to true and setup user data from jwt
  useEffect(() => {
    loadUserIfExists();
    if (getUser()) {
      let userData = getUser();
      dispatch(changeLoggedInUser(getUser()));
      if (userData && userData.isAdmin) {
        dispatch(changeActiveUserType("admin"));
        localStorage.removeItem("IsAllowed");
      } else if (userData && !userData.isAdmin) {
        dispatch(changeActiveUserType("conductor"));
        localStorage.removeItem("IsAllowed");
      } else {
        dispatch(changeActiveUserType("user"));
      }
    }
  }, []);

  useEffect(() => {
    let isStoryUser = localStorage.getItem("IsAllowed");
    if (isStoryUser === "false") {
      dispatch(changeActiveUserType("user"));
    }
  }, [localStorage.getItem("IsAllowed")]);

  useEffect(() => {
    (async () => {
      let res = await fetchStories();
      if (res.success) {
        await dispatch(changeStoryDetail(res.data.data));
      }
    })();
  }, []);

  window.onhashchange = () => {
    dispatch(changeStoryCharacter(null));
  };

  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route
            path="/invite/:inviteId"
            element={
              <>
                <Navbar title={"Story Invitation"} backButton={false}></Navbar>
                <Invitation />
              </>
            }
          />
          <Route
            path="/joinstory"
            element={
              <>
                <Navbar title={"Story Invitation"} backButton={false}></Navbar>
                <Invitation />
              </>
            }
          />
          <Route
            path="/story/:storyId"
            element={
              <>
                <Navbar
                  title={selectedStory ? selectedStory.title : "Loading..."}
                  backButton={true}
                ></Navbar>
                {socketReady && (
                  <React.Suspense fallback={<Loader />}>
                    <StoryView socket={socket} connectSocket={connectSocket} />
                  </React.Suspense>
                )}
              </>
            }
          />
          <Route
            path="/bucket/view"
            element={
              <>
                <Navbar
                  title={"S3 Bucket"}
                  backButton={true}
                ></Navbar>
                <AuthButton />
                {socketReady && (
                  <React.Suspense fallback={<Loader />}>
                    <BucketView socket={socket} connectSocket={connectSocket} />
                  </React.Suspense>
                )}
              </>
            }
          />
          <Route
            path="/messages/media/view"
            element={
              <>
                <Navbar
                  title={"Messages Media View"}
                  backButton={true}
                ></Navbar>
                <AuthButton />
                {socketReady && (
                  <React.Suspense fallback={<Loader />}>
                    <MessagesMediaView socket={socket} connectSocket={connectSocket} />
                  </React.Suspense>
                )}
              </>
            }
          />
          <Route
            path="/"
            element={
              <>
                <Navbar
                  title={"Secret Story Network"}
                  backButton={false}
                ></Navbar>
                <AuthButton />
                <Stories />
              </>
            }
          />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
